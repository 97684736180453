import React from 'react'
import { compose, get, map } from 'lodash/fp'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { breakpoints } from '@/utils/styles'

import { localize } from 'src/components/Localize'
import Container from '@/components/Container'

import Layout from 'src/components/Layouts'
import Headings from '@/components/Headings'
import PortableText from '@/components/PortableText'
import Paragraph from '@/components/Paragraph'
import Banner from '@/components/Banner'

const News = ({ path, pageContext, preview = false, data, ...other }) => {
  const { title, locale, siteGlobal, postImage, publishedAt, _rawBody } = pageContext
  const menuItems = compose(map('node'), get('allSanityMenus.edges'))(data)

  const navMenuItems = menuItems.map((menuItem) => ({
    ...menuItem,
    items: menuItem.items.map((item) => ({
      ...item,
      link: get('link.content.main.slug.current', item),
    })),
  }))

  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'short',
    day: '2-digit',
  }

  const articleDate = new Date(publishedAt).toLocaleDateString(locale, options)

  return (
    <Layout navMenuItems={navMenuItems} siteGlobal={siteGlobal} locale={locale}>
      <Banner backgroundImage={postImage}>
        <Overlay />
        <Container paddingVertical={40}>
          <Title>{title}</Title>
          <Meta>
            <MetaText fullWidth>{articleDate}</MetaText>
          </Meta>
        </Container>
      </Banner>
      <Section>
        <Container paddingVertical={40}>
          <Content>
            <PortableText blocks={_rawBody} />
          </Content>
        </Container>
      </Section>
    </Layout>
  )
}
// @ts-ignore
export default localize(News)

const Section = styled.section``
const Content = styled.div`
  height: 100%;
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1;
`
const Title = styled(Headings.h2)`
  text-transform: uppercase;
  color: #ffffff;
  text-shadow: 3px 5px 1px rgba(0, 0, 0, 0.2);
  max-width: 700px;
  margin: 8rem auto 0px;

  @media (min-width: ${breakpoints.l}px) {
    font-size: 36px;
  }
  @media (max-width: ${breakpoints.m}px) {
    font-size: 30px;
  }
`

const Meta = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0 80px;
`

const MetaText = styled(Paragraph)`
  color: ${(p) => p.theme.colors.card};
  text-align: center;
  text-transform: capitalize;

  @media (min-width: ${breakpoints.m}px) {
    font-size: 18px;
  }
`

export const query = graphql`
  query PostTemplateQuery {
    allSanityMenus {
      edges {
        node {
          menuLocation {
            mainMenu
            footerMenu
          }
          items {
            ... on SanityInternalLink {
              title {
                no
                tr
                _type
              }
              link {
                content {
                  main {
                    slug {
                      _type
                      tr {
                        current
                        _type
                      }
                      no {
                        current
                        _type
                      }
                    }
                  }
                }
              }
            }
          }
          title {
            no
            tr
            _type
          }
          slug {
            _type
            tr {
              current
              _type
            }
            no {
              current
              _type
            }
          }
        }
      }
    }
  }
`
