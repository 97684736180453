import React from 'react'
import BasePortableText from '@sanity/block-content-to-react'
import { Serializer } from 'src/utils/serializer'

import clientConfig from '../../../client-config'

const PortableText = ({ blocks }) => (
  <BasePortableText blocks={blocks} serializers={Serializer} {...clientConfig.sanity} />
)

export default PortableText
